<template>
  <el-card class="px-0">
    <template #header>
      <el-row>
        <el-col :span="12">
          <div style="display: flex; padding-top: 5px;">
            <h3 style="display: inline-block; vertical-align: top">
              {{ !state.collapsed ? `${translateText('gaz.interview.question.title')} ${data.stepSerial}` : `${data.stepSerial}.` }}
              <span :style="{ color: state.collapsed ? '' : '#a8abb2' }">{{ !state.collapsed ? `— ${currentQuestionTypeText}` : `${data.question}` }}</span>
            </h3>
          </div>
          <p
            v-if="state.collapsed"
            style="display:flex; align-items: center; gap: 8px"
            :style="{ color: state.collapsed ? '#a8abb2' : '' }"
          >
            <component :is="getIcon(state.type)" />
            {{ currentQuestionTypeText }}
          </p>
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-button-group v-if="!onlyMeta">
            <el-button
              style="width: auto;"
              :disabled="isFirst"
              type="primary"
              :icon="ArrowUpBold"
              plain
              @click="emitMoveUp"
            />
            <el-button
              style="width: auto;"
              :disabled="isLast"
              type="primary"
              :icon="ArrowDownBold"
              plain
              @click="emitMoveDown"
            />
            <el-button
              :disabled="!canAddConditions"
              style="width: auto;"
              type="primary"
              :icon="ArrowSvg"
              plain
              @click="addConditions"
            />
            <el-button
              style="width: auto;"
              type="danger"
              :icon="Delete"
              plain
              @click="emitDelete"
            />
          </el-button-group>
        </el-col>
      </el-row>
      <el-row justify="end">
        <el-button
          style="width: auto; margin-bottom: 0"
          type="primary"
          :icon="state.collapsed ? Plus : Minus"
          link
          @click="toggleCollapsed"
        >
          {{ state.collapsed ? 'Развернуть вопрос' : 'Свернуть вопрос' }}
        </el-button>
      </el-row>
    </template>

    <div v-if="!state.collapsed">
      <el-divider style="margin-top: 0" />
      <el-row>
        <el-col>
          <el-input
            v-model="state.question"
            :placeholder="translateText('gaz.interview.question.placeholder_question')"
            :class="[{'is-error': errors.question && showErrors}]"
            @update:modelValue="emitUpdateData"
          >
            <template v-if="errors.question && showErrors" #suffix>
              <el-icon color="#FF0000" size="20">
                <component :is="Warning" />
              </el-icon>
            </template>
          </el-input>
        </el-col>
      </el-row>
      <el-row style="padding-top: 20px;">
        <el-col>
          <el-input
            v-model="state.description"
            :placeholder="translateText('gaz.interview.question.placeholder_description')"
            :class="[{'is-error': errors.description && showErrors}]"
            @update:modelValue="emitUpdateData"
          >
            <template v-if="errors.description && showErrors" #suffix>
              <el-icon color="#FF0000" size="20">
                <component :is="Warning" />
              </el-icon>
            </template>
          </el-input>
        </el-col>
      </el-row>

      <el-row class="mb-20">
        <el-col>
          <stars-question
            v-if="data.type === QUESTION_TYPE.STARS"
            :key="data.uuid"
            :data="data"
            :show-errors="showErrors"
            :only-meta="onlyMeta"
            @update:data="questionDataUpdated"
          />

          <number-question
            v-if="data.type === QUESTION_TYPE.PLAIN_ANSWER_NUMBER"
            :key="data.uuid"
            :data="data"
            :step="step"
            :show-errors="showErrors"
            :only-meta="onlyMeta"
            :price="price"
            :money-settings-hidden="moneySettingsHidden"
            @update:data="questionDataUpdated"
          />

          <plain-answer-question
            v-if="data.type === QUESTION_TYPE.PLAIN_ANSWER"
            :key="data.uuid"
            :data="data"
            :show-errors="showErrors"
            :only-meta="onlyMeta"
            :price="price"
            :money-settings-hidden="moneySettingsHidden"
            @update:data="questionDataUpdated"
          />

          <list-question
            v-if="data.type === QUESTION_TYPE.LIST"
            :key="data.uuid"
            :data="data"
            :show-errors="showErrors"
            :only-meta="onlyMeta"
            @update:data="questionDataUpdated"
          />

          <date-question
            v-if="data.type === QUESTION_TYPE.DATE"
            :key="data.uuid"
            :data="data"
            :show-errors="showErrors"
            :only-meta="onlyMeta"
            @update:data="questionDataUpdated"
          />

          <file-question
            v-if="data.type === QUESTION_TYPE.FILE"
            :key="data.uuid"
            :data="data"
            :show-errors="showErrors"
            :only-meta="onlyMeta"
            @update:data="questionDataUpdated"
          />
        </el-col>
      </el-row>

      <el-row justify="start" class="mb-0">
        <el-col :span="8" :xs="24" :sm="12">
          <div style="display: flex; align-items: center; gap: 5px">
            <el-checkbox
              v-model="state.required"
              :label="translateText('gaz.interview.question.label')"
              :disabled="onlyMeta || state.notEmptyAnswer"
              @update:modelValue="emitUpdateData"
            />
            <el-tooltip :append-to-body="false" popper-class="generic-form_item_tooltip">
              <svg-icon
                size="sm"
                class="generic-form_item_help"
                core="question"
              />
              <template #content>
                <div v-html="translateText('gaz.interview.question.hint.required_not_null')" />
              </template>
            </el-tooltip>
          </div>
        </el-col>

        <el-col
          v-if="data.type === QUESTION_TYPE.PLAIN_ANSWER_NUMBER && data.dataType === 'type_int'"
          :span="12"
          :xs="24"
          :sm="12"
        >
          <div
            style="display: flex; align-items: center; gap: 5px"
          >
            <el-checkbox
              v-model="state.notEmptyAnswer"
              label="Нельзя ввести 0"
              :disabled="onlyMeta"
              @change="toggleRequired"
              @update:modelValue="emitUpdateData"
            />
            <el-tooltip :append-to-body="false" popper-class="generic-form_item_tooltip">
              <svg-icon
                size="sm"
                class="generic-form_item_help"
                core="question"
              />
              <template #content>
                <div v-html="translateText('gaz.interview.question.hint.required_null')" />
              </template>
            </el-tooltip>
          </div>
        </el-col>
      </el-row>
      <slot name="condition" />
    </div>
  </el-card>
</template>

<script lang="ts">
import {
  ArrowUpBold,
  ArrowDownBold,
  Delete,
  View,
  Hide,
  QuestionFilled,
  Warning,
  Plus,
  Minus,
} from '@element-plus/icons-vue';
import { DATA_TYPE, QUESTION_TYPE, QUESTION_TYPE_OPTIONS } from 'features/settings/interviews/constants';
import {
  computed,
  defineComponent, PropType, reactive, Ref, ref, watch,
} from 'vue';
import {
  IQuestionType, IQuestion, IPrice, IStep,
} from 'features/settings/interviews/interfaces';
import StarsQuestion from 'features/settings/interviews/components/question/StarsQuestion.vue';
import NumberQuestion from 'features/settings/interviews/components/question/NumberQuestion.vue';
import PlainAnswerQuestion from 'features/settings/interviews/components/question/PlainAnswerQuestion.vue';
import ListQuestion from 'features/settings/interviews/components/question/ListQuestion.vue';
import DateQuestion from 'features/settings/interviews/components/question/DateQuestion.vue';
import FileQuestion from 'features/settings/interviews/components/question/FileQuestion.vue';
import { translate, useTranslate } from 'magner';
import ArrowSvg from '../../assets/icons/arrows.svg';
import CalendarSvg from '../../assets/icons/calendar.svg';
import ListCheckSvg from '../../assets/icons/list-check.svg';
import NumberSvg from '../../assets/icons/number.svg';
import StarSvg from '../../assets/icons/star.svg';
import UploadSvg from '../../assets/icons/upload.svg';
import WritingSvg from '../../assets/icons/writing.svg';

export default defineComponent({
  name: 'InterviewQuestion',
  components: {
    NumberQuestion,
    PlainAnswerQuestion,
    StarsQuestion,
    ListQuestion,
    DateQuestion,
    FileQuestion,
  },
  props: {
    data: {
      type: Object as PropType<IQuestionType>,
      required: true,
    },
    step: {
      type: Object as PropType<IStep>,
      required: true,
    },
    prices: {
      type: Array as PropType<IPrice[]>,
      required: true,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    moneySettingsHidden: {
      type: Boolean,
      default: false,
    },
    canAddConditions: {
      type: Boolean,
      default: false,
    },
    showErrors: Boolean,
    onlyMeta: Boolean,
  },
  emits: [
    'update:data',
    'delete',
    'move-up',
    'move-down',
    'add-conditions',
    'errors',
  ],
  setup (props, { emit }) {
    const { customT } = useTranslate();

    const errors: {
      question: boolean;
      description: boolean;
    } = reactive({
      question: false,
      description: false,
    });
    //
    const state: IQuestion = reactive({
      uuid: props.data.uuid,
      serial: props.data.serial,
      stepSerial: props.data.stepSerial,
      type: props.data.type,
      question: props.data.question,
      description: props.data.description,
      collapsed: props.data.collapsed,
      required: props.data.required,
      notEmptyAnswer: props.data.notEmptyAnswer || false,
      inZeroCondition: props.data.inZeroCondition || false,
    });

    /**
     * Ловим обновления пропсов
     */
    watch(() => props.data, () => {
      state.uuid = props.data.uuid;
      state.serial = props.data.serial;
      state.stepSerial = props.data.stepSerial;
      state.type = props.data.type;
      state.question = props.data.question;
      state.description = props.data.description;
      state.collapsed = props.data.collapsed;
      state.required = props.data.required;
      state.notEmptyAnswer = props.data.notEmptyAnswer || false;
      state.inZeroCondition = props.data.inZeroCondition || false;
    }, { deep: true });
    /**
     * текущие заполненные данные в вопросе
     */
    const currentValue: Ref<IQuestionType> = ref(props.data);
    /**
     * Метод валидирует введенные данные
     */
    const validateData = () => {
      errors.question = state.question === '';
      // errors.description = state.description === '';

      emit('errors', errors.question || errors.description);
    };
    /**
     * Метод включает обзятельность, если включен "нельзя ввести 0"
     * */
    const toggleRequired = () => {
      if (state.notEmptyAnswer) {
        state.required = true;
      }
    };
    /**
     * Метод передает "наверх" изменение общих полей
     */
    const emitUpdateData = () => {
      validateData();

      const emitValue = {
        ...state,
        ...currentValue.value,
      };

      if (!(props.data.type === QUESTION_TYPE.PLAIN_ANSWER_NUMBER && props.data.dataType === 'type_int')) {
        emitValue.notEmptyAnswer = false;
      }

      emit('update:data', emitValue);
    };
    /**
     * Метод обрабатывает изменение данных в компоненте и передает "наверх"
     * @param data
     */
    const questionDataUpdated = (data: { data: IQuestionType, errors: any }) => {
      currentValue.value = data.data;
      emitUpdateData();
    };
    /**
     * Метод передает "наверх" команду на удаление вопроса
     */
    const emitDelete = () => {
      emit('delete');
    };
    /**
     * Метод передает "наверх" команду на перемещение вопроса вверх
     */
    const emitMoveUp = () => {
      emit('move-up');
    };
    /**
     * Метод передает "наверх" команду на перемещение вопроса вниз
     */
    const emitMoveDown = () => {
      emit('move-down');
    };
    /**
     * Метод раскрывает/скрывает вопрос
     */
    const toggleCollapsed = () => {
      state.collapsed = !state.collapsed;
    };
    /**
     * Метод подставляет нужную иконку
     * */
    const getIcon = (type: string) => {
      switch (type) {
        case QUESTION_TYPE.PLAIN_ANSWER:
          return WritingSvg;
        case QUESTION_TYPE.PLAIN_ANSWER_NUMBER:
          return NumberSvg;
        case QUESTION_TYPE.DATE:
          return CalendarSvg;
        case QUESTION_TYPE.FILE:
          return UploadSvg;
        case QUESTION_TYPE.LIST:
          return ListCheckSvg;
        case QUESTION_TYPE.STARS:
          return StarSvg;
        default:
          return null;
      }
    };
    /**
     * Метод отправляет запрос на добавление условий по отображению текущего вопроса
     */
    const addConditions = () => {
      emit('add-conditions');
    };
    const currentQuestionTypeText = computed(
      () => customT(translate(Object.values(QUESTION_TYPE_OPTIONS).filter((i) => i.id === state.type)[0].name)),
    );

    const price = computed(() => {
      if (props.data.type === QUESTION_TYPE.PLAIN_ANSWER_NUMBER && props.data.dataType === 'type_int') {
        return props.prices.find((item) => item.questionId === props.data.uuid)?.price ?? 0;
      }

      return 0;
    });

    return {
      state,
      errors,

      emitUpdateData,
      questionDataUpdated,
      emitDelete,
      emitMoveUp,
      emitMoveDown,
      toggleCollapsed,
      addConditions,
      translateText: (code: string) => customT(translate(code)),
      getIcon,
      toggleRequired,

      QUESTION_TYPE,
      DATA_TYPE,

      ArrowUpBold,
      ArrowDownBold,
      Delete,
      View,
      Hide,
      Plus,
      Minus,
      QuestionFilled,
      Warning,
      ArrowSvg,

      currentQuestionTypeText,
      price,
    };
  },
});
</script>

<style lang="scss" scoped>
.question {
  &_with-conditions {
    background-color: #409eff10;
  }
}

.mb-20 {
  padding: 20px 0 !important;
}

:deep(.el-row:not(.condition__row)) {
  margin-bottom: 0 !important;
}

:deep(.el-card__header) {
  border-bottom: 0;
}

:deep(.el-card__body) {
  padding-top: 0;
}
</style>
