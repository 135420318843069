import {
  IInterview, IListQuestion, IListQuestionOption, IPrice, IQuestion, IQuestionType, IStep,
} from 'features/settings/interviews/interfaces';
import { Event } from 'features/events/types/hubstr';
import { validate, v4 as uuidv4 } from 'uuid';
import {
  DATA_TYPE,
  DATE_DATA_TYPE_OPTIONS,
  QUESTION_TYPE,
  QUESTION_TYPE_OPTIONS,
} from 'features/settings/interviews/constants';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { computed } from 'vue';
import { request } from '~/utils/request';

interface Pager {
  currentPage: number,
  totalPages: number,
  totalItems: number,
}

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.tz.setDefault('Europe/Moscow');

const parseErrorUuid = (error: { message: string }, steps: IStep[]) => {
  if (!error.message.includes('UUID')) {
    return error.message;
  }

  const result = error.message.split(' ').filter((item) => item !== 'UUID').map((item: string) => {
    if (!validate(item.slice(0, item.length - 1))) {
      return item;
    }

    const questions = steps.map((step) => step.questions).flat();

    const question = questions.find((q) => q.uuid === item.slice(0, item.length - 1));

    return `${question?.question}.`;
  });

  return result.join(' ');
};

/**
 * Получение полного списка опросников
 */
export const interviewRead = request.table<IInterview>(async ({ api, data }) => {
  try {
    const pagination = `count=${data.pagination?.items || 25}&page=${data.pagination?.page || 1}`;
    const search = data.filters.name
      ? `&filters[0][id]=search&filters[0][value]=${data.filters.name}`
      : '';

    const res = await api.get<{ list: IInterview[], pager: Pager }>(
      `/api/admin/poll/full_list?${pagination}${search}`,
    );

    if (res.error) {
      return {
        data: {
          rows: [],
          pagination: null,
        },
      };
    }

    return {
      data: {
        rows: res.data.list.map((item: IInterview) => ({
          ...item,
          pollResults: item.id,
          createdAt: dayjs(item.createdAt).tz('Europe/Moscow').format('YYYY-MM-DDTHH:mm:ss'),
        })),
        pagination: {
          currentPage: res.data.pager?.currentPage || 1,
          totalPages: res.data.pager?.totalPages || 1,
          totalItems: res.data.pager?.totalItems || 0,
        },
      },
    };
  } catch (e) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }
});
/**
 * Получение детальной информации об опроснике
 */
export const interviewGet = request.card<{interviewLayout: IInterview}>(async ({ api, data, parseError }) => {
  try {
    const res = await api.get<IInterview>(`/api/admin/poll/detail?poll=${data.id}`);
    //
    // проходим по всему опроснику и генерируем новые uuid
    //
    // if (res.data?.steps && !res.data?.isPublished) {
    //   res.data.steps = [...res.data?.steps.map((step: IStep) => ({
    //     ...step,
    //     uuid: uuidv4() as string,
    //     questions: [...step.questions.map((question: IQuestionType) => {
    //       if (question.type === QUESTION_TYPE.LIST) {
    //         //
    //         // новый uuid для вариантов ответа
    //         //
    //         return {
    //           ...question,
    //           options: [...(question as IListQuestion).options.map((option: IListQuestionOption) => ({
    //             ...option,
    //             uuid: uuidv4() as string,
    //           }))],
    //           uuid: uuidv4() as string,
    //         };
    //       }
    //
    //       return {
    //         ...question,
    //         uuid: uuidv4() as string,
    //       };
    //     })],
    //   }))];
    // }

    const out = {
      id: data.id,
      name: res.data?.name,
      description: res.data?.description,
      steps: res.data?.steps,
      isPublished: res.data?.isPublished,
      basePrice: res.data?.basePrice,
      prices: res.data?.prices,
      canDelete: res.data?.canDelete,
      totalLimit: res.data?.totalLimit,
    };

    // convert question type: type_plain_answer_number to type_plain_answer
    if (out.steps) {
      out.steps = out.steps.map((step: IStep) => ({
        ...step,
        questions: step.questions.map((question: IQuestionType) => ({
          ...question,
          type: question.type === QUESTION_TYPE.PLAIN_ANSWER && question.dataType === DATA_TYPE.NUMBER
            ? QUESTION_TYPE.PLAIN_ANSWER_NUMBER
            : question.type,
        })),
      }));
    }

    return {
      data: {
        interviewLayout: out,
      },
    };
  } catch (e) {
    return {
      error: { message: '', fields: {} },
    };
  }
});

export const interviewCreate = request.custom(async ({
  api, data, parseError, router,
}) => {
  const out = {
    name: data.data.interviewLayout.name,
    description: data.data.interviewLayout.description,
    steps: data.data.interviewLayout.steps,
    basePrice: data.data.interviewLayout.basePrice && data.data.interviewLayout.basePrice > 0
      ? data.data.interviewLayout.basePrice : null,
    prices: data.data.interviewLayout.prices.map((p: IPrice) => ({
      name: p.name,
      price: p.price,
      questionId: p.questionId,
    })),
  };

  // convert question type: type_plain_answer to type_plain_answer_number
  if (out.steps) {
    out.steps = out.steps.map((step: IStep) => ({
      ...step,
      questions: step.questions.map((question: IQuestionType) => ({
        ...question,
        type: question.type === QUESTION_TYPE.PLAIN_ANSWER_NUMBER
          ? QUESTION_TYPE.PLAIN_ANSWER
          : question.type,
      })),
    }));

    out.steps.forEach((s: IStep) => {
      s.questions.forEach((q: IQuestionType) => {
        // если нет inZeroCondition - ставим inZeroCondition = false
        if (!('inZeroCondition' in s)) {
          s.inZeroCondition = false;
        }

        // totalLimit = null, если выкл. свитч "Лимит шага"
        if (!s.hasStepLimit) {
          s.totalLimit = null;
        }
      });
    });
  }

  if (data.data.interviewLayout.totalLimit) {
    out.totalLimit = data.data.interviewLayout.totalLimit;
  }

  const res = await api.post('/api/admin/poll/create', out);

  if (res.error) {
    if (res.error) {
      const errorMessage = parseError(res.error);

      return {
        error: {
          ...errorMessage,
          message: parseErrorUuid(errorMessage as any, data.data.interviewLayout.steps),
        },
      };
    }
  }

  router.push({ name: 'interviews' });
  return { data: 'ok' };
});

export const interviewUpdate = request.custom(async ({ api, data, parseError }) => {
  const questions: {uuid: string, question: string, description: string, intLimit?: number, totalLimit?: number}[] = [];

  // convert question type: type_plain_answer to type_plain_answer_number
  if (data.data.interviewLayout.steps) {
    data.data.interviewLayout.steps = data.data.interviewLayout.steps.map((step: IStep) => ({
      ...step,
      questions: step.questions.map((question: IQuestionType) => ({
        ...question,
        type: question.type === QUESTION_TYPE.PLAIN_ANSWER_NUMBER
          ? QUESTION_TYPE.PLAIN_ANSWER
          : question.type,
      })),
    }));
  }

  data.data.interviewLayout.steps.forEach((step: IStep) => {
    step.questions.forEach((q: IQuestion) => {
      // если нет inZeroCondition - ставим inZeroCondition = false
      if (!('inZeroCondition' in step)) {
        step.inZeroCondition = false;
      }
      // intLimit = null, если выкл. свитч "Лимит респондента"
      if (q.type === QUESTION_TYPE.PLAIN_ANSWER && !q.enabledIntLimit) {
        q.intLimit = 0;
      }
      // totalLimit = null, если выкл. свитч "Лимит шага"
      if (!step.hasStepLimit) {
        step.totalLimit = null;
      }
      // delete totalLimit, если выкл. свитч "Лимит вопроса"
      if (!q.enabledTotalLimit) {
        delete q.totalLimit;
      }
      // delete price, если выкл. свитч "Стоимость"
      if (!q.enabledPrice) {
        delete q.price;
      }
    });
  });

  if (data.data.interviewLayout.onlyMeta) {
    data.data.interviewLayout.steps.map((s: IStep) => {
      s.questions.map((q: IQuestion) => {
        const qi = {
          uuid: q.uuid,
          question: q.question,
          description: q.description,
        };

        if (q.intLimit) {
          qi.intLimit = q.intLimit;
        }

        if (q.totalLimit) {
          qi.totalLimit = q.totalLimit;
        }

        if (q.notIncludeInLimit) {
          qi.notIncludeInLimit = q.notIncludeInLimit;
        }

        questions.push(qi);
        return q;
      });

      return s;
    });

    const res = await api.patch('/api/admin/poll/edit', {
      poll: data.id,
      totalLimit: data.data.interviewLayout?.totalLimit,
      questions,
      stepsLimits: [],
    });

    if (res.error) {
      const errorMessage = parseError(res.error);

      return {
        error: {
          ...errorMessage,
          message: parseErrorUuid(errorMessage as any, data.data.interviewLayout.steps),
        },
      };
    }
  } else {
    const out = {
      poll: data.id,
      name: data.data.interviewLayout.name,
      description: data.data.interviewLayout.description,
      steps: data.data.interviewLayout.steps,
      basePrice: data.data.interviewLayout.basePrice && data.data.interviewLayout.basePrice > 0
        ? data.data.interviewLayout.basePrice : null,
      prices: data.data.interviewLayout.prices.map((p: IPrice) => ({
        name: p.name,
        price: p.price,
        questionId: p.questionId,
      })),
    };

    if (data.data.interviewLayout.totalLimit) {
      out.totalLimit = data.data.interviewLayout.totalLimit;
    }

    const res = await api.put('/api/admin/poll/edit', out);

    if (res.error) {
      const errorMessage = parseError(res.error);

      return {
        error: {
          ...errorMessage,
          message: parseErrorUuid(errorMessage as any, data.data.interviewLayout.steps),
        },
      };
    }
  }

  return { data: 'ok' };
});

export const interviewDelete = request.card(async ({
  api, data, parseError, router,
}) => {
  const res = await api.delete(`/api/admin/poll/delete?poll=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'interviews' });
  return { data: 'ok' };
});

export const interviewClone = request.custom(async ({
  api, data, parseError,
}) => {
  const res = await api.post('/api/admin/poll/clone', {
    poll: data,
  });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return { data: res.data.data.id };
});
